import React from "react";
import { PlayIcons, CloseIcon } from "./Icons";
import axios from "axios";

const fetchImg = name => {
  try {
    const img = require(`./images/webinar/${name}.png`);
    return img;
  } catch (error) {
    const img = require(`./images/webinar/default.png`);
    return img;
  }
};
const App = () => {
  const [video, setVideo] = React.useState([]);

  const [subscribed, setSubcribed] = React.useState(false);

  const [currentVideo, setCurrentVideo] = React.useState(null);

  React.useEffect(() => {
    axios
      .get("/videos/list")
      .then(res => res.data)
      .then(res => res.filter(e => e.category === "webinar"))
      .then(data => {
        setVideo(data);
      })
      .catch(() => {});
  }, []);

  const openModel = data => setCurrentVideo(data);

  return (
    <>
      <HeaderSection subscribed={subscribed} setSubcribed={setSubcribed} />
      {subscribed ? (
        <YoutubeVideos video={video} openModel={openModel} />
      ) : (
        <VideoContainerPage />
      )}
      {currentVideo !== null ? (
        <Modal
          closeModel={() => setCurrentVideo(null)}
          currentVideo={currentVideo}
        />
      ) : null}
    </>
  );
};

const VideoBg = require("../assets/images/webinar_thumb.jpg");

const HeaderSection = ({ subscribed, setSubcribed }) => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const handleCLick = () => {
    axios.post("/videos/subscribe", { name, email });
    setEmail("");
    setName("");
    setSubcribed(true);
  };
  return (
    <section className="assistant_slider beta-main-slider trial_mb_80 bg_landing_zendesk bg_market_Webinar_page">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
            <div className="main-slider-left">
              <h1>
                Deep-dive into the product. Watch recorded webinars and see how
                Workativ Assistant can help you transform workplace support
              </h1>
              <>
                <p>Subscribe now to get started.</p>
                <div className="main-slider-button beta-button">
                  <form name="form">
                    {subscribed === false ? (
                      <>
                        <input
                          type="text"
                          id="beta-name"
                          name="firstname"
                          value={name}
                          disabled={subscribed === true}
                          placeholder="Name"
                          onChange={e => setName(e.target.value)}
                        />
                        <input
                          type="text"
                          id="beta-email"
                          name="lastname"
                          placeholder="Email"
                          value={email}
                          placeholder="Email"
                          disabled={subscribed === true}
                          onChange={e => setEmail(e.target.value)}
                        />
                        {/* {email.match(
                          /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/g
                        ) === null && email.trim() !== "" ? (
                          <span>Invalid Email Format</span>
                        ) : null} */}
                      </>
                    ) : null}
                    {/* Button trigger modal */}
                    <button
                      type="button"
                      id="register-for-trail"
                      className={
                        email.match(
                          /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/g
                        ) !== null && name.trim() !== ""
                          ? "btn btn-primary"
                          : "btn btn-primary disabled_button"
                      }
                      onClick={handleCLick}
                    >
                      {subscribed === true ? "Subscribed" : "Subscribe Now"}
                    </button>
                  </form>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const VideoContainerPage = () => {
  return (
    <section className="webinar_page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="webinar_img_container">
              <img src={VideoBg} alt={VideoBg} />
              <span className="para_center_videos webinar_center_content">
                Subscribe now to get started
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const VideosLists = ({ MenuLists }) => {
  return (
    <section className="video_lists">
      <div className="video_lists_lg">
        <div className="container video_lists_container">
          <ul className="video_list_ul">
            {MenuLists.map(data => (
              <li
                className="video_list_li video_list_li_active"
                onClick={e => data.onclick()}
              >
                <div className="video_svg_iocn">{data.icon}</div>
                <div className="video_content_li">{data.menu}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

const YoutubeVideos = ({ video, openModel }) => {
  return (
    <section className="utube_videos">
      <div className="container">
        <div className="utube_rows">
          {video
            .map(e => ({
              ...e,
              url: e.url.replace("watch?v=", "embed/")
            }))
            .map((data, index) => (
              <div className="utube_col_4" key={index}>
                <div className="utube_iframe" onClick={e => openModel(data)}>
                  <span>
                    <PlayIcons />
                  </span>
                  <img src={fetchImg(data.icon)} />
                </div>
                <div className="utube_content">
                  <p>{data.title}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export const Modal = ({ currentVideo, closeModel }) => {
  return (
    <div className="modal_popup modal_show">
      <div className="modal_popup_video">
        <div className="video_iframe">
          <iframe
            src={currentVideo.url}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="video"
          ></iframe>
          <span onClick={e => closeModel()}>
            <CloseIcon />
          </span>
        </div>
      </div>
    </div>
  );
};

export const WebniarApp = App;

// ReactDOM.render(<App />, document.getElementById("app-root"));
